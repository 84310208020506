<template lang="html">
  <div>
    <div class="title-area mt-20">
      <div class="btn-item">
        <button type="button" class="element-btn bg-blue" @click="registSound()"><i class="icon-img ic-btn-add"></i>신규 등록</button>
      </div>
    </div>
    <div class="table-head mt-xs">
        <div class="total-item"><span class="total">Total {{totalCount}}</span></div>
      <div class="btn-item">
        <button type="button" class="btn-table-head">
          <span class="btn-txt" @click="deleteSoundList()">선택 삭제</span>
          <i class="icon-img ic-list-select-delete"></i>
        </button>
      </div>
    </div>
    <div class="table-a">
      <table>
        <colgroup>
            <col style="width:60px">
            <col style="width:80px">
            <col style="width:15%" span="3">
            <col style="width:auto">
            <col style="width:10%">
            <col style="width:80px">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">
            <div class="element-checkbox checkbox-text">
              <input type="checkbox" id="chk-all" class="checkbox-input" v-model="isCheckAll" @click="checkAll($event.target.checked)"><label for="chk-all" class="checkbox-label">전체</label>
            </div>
          </th>
          <th scope="col">No.</th>
          <th scope="col">음원종류</th>
          <th scope="col">회사</th>
          <th scope="col">음원이름</th>
          <th scope="col">비고</th>
          <th scope="col">미리듣기</th>
          <th scope="col">수정</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(sItem, idx) in soundList" :key="idx">
          <td>
            <div class="element-checkbox">
              <input type="checkbox" :id="'check_' + idx" class="checkbox-input" @change="checkItem($event)" v-model="sItem.selector"><label :for="'check_' + idx" class="checkbox-label">선택</label>
            </div>
          </td>
          <td>{{ totalCount - idx - (limit * ( currentPage - 1 )) }}</td>
          <td>{{soundTypeDesc[sItem.soundType]}}</td>
          <td>{{sItem.companyName}}</td>
          <td>{{sItem.soundName}}</td>
          <td>{{sItem.soundDescription}}</td>
          <td>
            <DropdownPlay :fileName="sItem.fileGuid"></DropdownPlay>
          </td>
          <td><button type="button" class="btn-modify" @click="updateSound(sItem.soundGuid)"><i class="icon-img ic-txt-list-edit"></i><span class="for-a11y">수정하기</span></button></td>
        </tr>
        </tbody>
      </table>
    </div>
    
    <paging-component :totalCount="totalCount" :limit="limit" :currentPage="currentPage" @chgCurrentPage="chgCurrentPage"></paging-component>


    <!-- 음원 추가 다이얼로그 START -->
    <div class="module-dimmed" v-if="showSoundAdminDialog">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">음원추가</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="form-group">
              <div class="form-item">
                <span class="form-label">음원종류<em class="essential">*</em></span>
                <div class="form-inp">
                  <t-rich-select
                    :options="soundTypeList"
                    class="border border-solid rounded-md text-gray-700 border-gray-300 outline-none w-62"
                    textAttribute="value"
                    valueAttribute="id"
                    :hideSearchBox="true"
                    v-model="soundType"
                    :fixedClasses="richSelectXL"
                  >
                  </t-rich-select>
                </div>
              </div>
              <div class="form-item">
                <span class="form-label">음원파일 업로드<em class="essential">*</em></span>
                <div class="form-inp">

                  <div v-if="soundFileInput" class="box-file-upload mt-xs">
                    <div class="text-sm font-bold txt-blue cursor-pointer text-right mt-1 mb-5" @click="retrySoundFile()">새로올리기</div>
                    <div class="block text-center mb-2">
                      <div class="flex justify-center items-center">
                        <i class="icon-img ic-map-add-list-icon mr-3"></i>
                      <div>{{ soundFileInput.name }}</div>
                      </div>
                    </div>
                  </div>
                  
                  <div v-else class="box-file-upload" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input type="file" class="w-px h-px opacity-0 overflow-hidden absolute" @change="registSoundFile()" id="soundFile" ref="soundFile" accept=".wav, .mp3" />
                  
                    <label for="soundFile" class="block cursor-pointer">
                      <div class="flex flex-col justify-center items-center">
                        <i class="icon-img ic-m-file-upload"></i>
                        <span class="file-desc">이곳을 클릭하여 파일 업로드 or 파일 드래그</span>
                        <span class="file-name">지원 파일 확장자 *.wav, *.mp3</span>
                      </div>
                    </label>
                  </div>

                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="!soundFileInput">필수 입력 사항 입니다.</p>

                </div>
              </div>
              <div class="form-item">
                <span class="form-label">음원이름<em class="essential">*</em></span>
                <div class="form-inp">
                  <input type="text" class="element-input size-lg" v-model="soundName" placeholder="음원명">
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="soundName==''">필수 입력 사항 입니다.</p>
                </div>
              </div>
              
              <div class="form-item">
                <span class="form-label">비고</span>
                <div class="form-inp">
                  <input type="text" class="element-input size-lg" v-model="soundDescription" placeholder="내용을 입력해주세요">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-gray size-lg" @click="closeSoundAdminDialog()">닫기</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="registSoundSubmit()" :disabled="checkRegistDisable()">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeSoundAdminDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 음원 추가 다이얼로그 END -->


    <!-- 음원 수정 다이얼로그 START -->
    <div class="module-dimmed" v-if="showSoundUpdateDialog">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">음원수정</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="form-group">
              <div class="form-item">
                <span class="form-label">음원종류<em class="essential">*</em></span>
                <div class="form-inp">
                  <t-rich-select
                    :options="soundTypeList"
                    class="border border-solid rounded-md text-gray-700 border-gray-300 outline-none w-62"
                    textAttribute="value"
                    valueAttribute="id"
                    :hideSearchBox="true"
                    v-model="soundType"
                    :fixedClasses="richSelectXL"
                  >
                  </t-rich-select>
                </div>
              </div>
              <div class="form-item">
                <span class="form-label">음원파일 업로드</span>
                <div class="form-inp">

                  <div v-if="audioFile != null" class="mb-2">
                    <audio ref="preview" controls="true" :src="audioFile"></audio>

                    <!-- <av-line
                      :audio-src="audioFile"
                    ></av-line> -->

                  </div>
                  
                  <div v-if="soundFileInput" class="box-file-upload mt-xs">
                    <div class="text-sm font-bold txt-blue cursor-pointer text-right mt-1 mb-5" @click="retrySoundFile()">새로올리기</div>
                    <div class="block text-center mb-2">
                      <div class="flex justify-center items-center">
                        <i class="icon-img ic-map-add-list-icon mr-3"></i>
                      <div>{{ soundFileInput.name }}</div>
                      </div>
                    </div>
                  </div>
                  
                  <div v-else class="box-file-upload" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input type="file" class="w-px h-px opacity-0 overflow-hidden absolute" @change="registSoundFile()" id="soundFile" ref="soundFile" accept=".wav, .mp3" />
                  
                    <label for="soundFile" class="block cursor-pointer">
                      <div class="flex flex-col justify-center items-center">
                        <i class="icon-img ic-m-file-upload"></i>
                        <span class="file-desc">이곳을 클릭하여 파일 업로드 or 파일 드래그</span>
                        <span class="file-name">지원 파일 확장자 *.wav, *.mp3</span>
                      </div>
                    </label>
                  </div>

                </div>
              </div>
              <div class="form-item">
                <span class="form-label">음원이름<em class="essential">*</em></span>
                <div class="form-inp">
                  <input type="text" class="element-input size-lg" v-model="soundName" placeholder="음원명">
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="soundName==''">필수 입력 사항 입니다.</p>
                </div>
              </div>
              
              <div class="form-item">
                <span class="form-label">비고</span>
                <div class="form-inp">
                  <input type="text" class="element-input size-lg" v-model="soundDescription" placeholder="내용을 입력해주세요">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-gray size-lg" @click="closeSoundUpdateDialog()">닫기</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="updateSoundSubmit()" :disabled="checkUpdateDisable()">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeSoundUpdateDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 음원 수정 다이얼로그 END -->

    <!-- 삭제 다이얼로그 START -->
    <div class="module-dimmed" v-if="showDeleteDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="text-lg font-bold text-red-700 text-center">다시 한번 확인해 주세요.</p>
            <div class="red-box mt-s">
              <p class="title">체크사항</p>
              <p class="desc-a">
                1. 삭제된 내용은 복구 할 수 없습니다.<br>
              </p>
            </div>
            <p class="confirm-text">관련된 내용이 삭제됩니다.<br>삭제하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="deleteSoundListCancel()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="deleteSoundListAction()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="deleteSoundListCancel()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 삭제 다이얼로그 END -->
    

  </div>
</template>

<script>
import PagingComponent from "@/components/elements/paging"
import DropdownPlay from "@/components/elements/DropdownPlay"
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"
import { richSelectXL } from "@/references/fixedClass"

import { soundTypeDesc } from "@/references/config"

export default {
  created () {
    this.companyGuid = this.getCompanyGuid
    this.getSoundList()
  },
  components: {
    PagingComponent,
    DropdownPlay
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCompanyGuid"]),
  },
  data () {
    return {
      companyGuid: '',

      soundTypeDesc,
      richSelectXL,

      limit: 15,
      currentPage: 1,
      totalCount: 0,
      soundList: [],

      selectedItem: [],
      isCheckAll: false,

      showSoundAdminDialog: false,
      showSoundUpdateDialog: false,
      soundTypeList: [
        {id:'detect', value: '불법 카메라 탐지'},
        {id:'offline', value: '단말기 오프라인'}
      ],
      soundType: 'detect',
      soundName: '',
      soundDescription: '',
      soundFileInput: null,
      soundGuid: '',

      showDeleteDialog: false,
      audioFile: null,
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    
    checkAll (checked) {
      this.isCheckAll = checked;
      for (let i in this.soundList) {
        this.soundList[i].selector = this.isCheckAll;
      }
    },
    checkItem () {
      for (let i in this.soundList) {
        if(! this.soundList[i].selector) {
          this.isCheckAll = false;
          return;
        } else {
          this.isCheckAll = true;
        }
      }
    },
    async getSoundList() {
      let reqObj = {}
      reqObj.target = `/setting/sound/list/${this.companyGuid}`;
      reqObj.method = "get";
      reqObj.params = {
        "offset": this.currentPage - 1,
        "limit": this.limit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        let tempList = result.msg.retData.content
        let addSelector = tempList.map(data => ({ ...data, selector: false}))
        this.soundList = [...addSelector]

        this.totalCount = result.msg.retData.totalElements
      }
    },

    chgCurrentPage (num) {
      this.currentPage = num
      this.getSoundList()
    },

    deleteSoundListCancel () {
      this.showDeleteDialog = false
    },
    deleteSoundList () {
      this.selectedItem = [];
      for (let i in this.soundList) {
        if(this.soundList[i].selector) {
          this.selectedItem.push({"soundGuid":this.soundList[i].soundGuid});
        }
      }

      if(this.selectedItem.length > 0) {
        this.showDeleteDialog = true
      }
    },
    async deleteSoundListAction() {
      let reqObj = {}
      reqObj.target = `/setting/sound/deleteList`;
      reqObj.method = "delete";
      reqObj.params = {};
      reqObj.data = this.selectedItem
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'삭제했습니다.'})
        
        this.showDeleteDialog = false
        this.getSoundList()
      }
    },
    registSound () {
      this.showSoundAdminDialog = true;
    },
    async updateSound (guid) {
      let reqObj = {}
      reqObj.target = `/setting/sound/info/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {}
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.soundGuid = guid;
        this.soundType = result.msg.retData.soundType
        this.soundName = result.msg.retData.soundName
        this.soundDescription = result.msg.retData.soundDescription
        this.getSoundData(result.msg.retData.fileGuid)
      }


      this.showSoundUpdateDialog = true;
    },



    async getSoundData (guid) {
      let reqObj = {}
      reqObj.target = `/files/sound/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      // this.audioFile = "data:audio/wav;base64," + result.msg.retData;
      this.audioFile = result.msg.retData;
    },

    closeSoundAdminDialog () {
      this.soundFileInput = null,
      this.soundType = 'detect'
      this.soundName = ''
      this.soundDescription = ''
      this.audioFile = null
      this.showSoundAdminDialog = false
    },

    closeSoundUpdateDialog () {
      this.soundFileInput = null,
      this.soundGuid = ''
      this.soundType = 'detect'
      this.soundName = ''
      this.soundDescription = ''
      this.audioFile = null
      this.showSoundUpdateDialog = false
    },


    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.soundFile.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    onChange() {
      this.soundFileInput = this.$refs.soundFile.files[0];
    },
    registSoundFile () {
      this.onChange()
    },
    retrySoundFile () {
      this.soundFileInput = null
    },

    checkRegistDisable () {
      let retVal = true;
      if(this.soundName !== "" 
      && this.soundType !== "" 
      && this.soundFileInput != null
      ) retVal = false;
      return retVal;
    },

    checkUpdateDisable () {
      let retVal = true;
      if(this.soundName !== "" 
      && this.soundType !== "" 
      ) retVal = false;
      return retVal;
    },




    registSoundSubmit () {
      this.registSoundAction()
    },
    async registSoundAction () {
      let reqObj = {}
      reqObj.target = "/setting/sound/regist";
      reqObj.method = "post";
      reqObj.params = {};
      
      const frm = new FormData();
      frm.append("companyGuid", this.companyGuid);
      frm.append("soundType", this.soundType);
      frm.append("soundName", this.soundName);
      frm.append("soundDescription", this.soundDescription);
      frm.append("mapGuid", this.mapGuid);
      if(this.soundFileInput != null) frm.append("soundFileInput", this.soundFileInput);
      reqObj.data = frm;

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'등록 했습니다.'})
        
        this.soundFileInput = null,
        this.soundType = 'detect'
        this.soundName = ''
        this.soundDescription = ''
        this.showSoundAdminDialog = false

        this.getSoundList();
      }
    },

    updateSoundSubmit () {
      this.updateSoundAction()
    },
    async updateSoundAction () {
      let reqObj = {}
      reqObj.target = "/setting/sound/update";
      reqObj.method = "put";
      reqObj.params = {};
      
      const frm = new FormData();
      frm.append("companyGuid", this.companyGuid);
      frm.append("soundGuid", this.soundGuid);
      frm.append("soundType", this.soundType);
      frm.append("soundName", this.soundName);
      frm.append("soundDescription", this.soundDescription);
      frm.append("mapGuid", this.mapGuid);
      if(this.soundFileInput != null) frm.append("soundFileInput", this.soundFileInput);
      reqObj.data = frm;

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'수정 했습니다.'})
      
        this.soundFileInput = null,
        this.soundGuid = ''
        this.soundType = 'detect'
        this.soundName = ''
        this.soundDescription = ''
        this.showSoundUpdateDialog = false

        this.getSoundList();
      }
    }


  },
  
  
}
</script>
